<template>
  <el-container>
    <el-main>
      <div class="login-container">
        <div class="left">
          <h1>Welcome！</h1>
          <h2>欢迎来到中地行集团面试测评系统</h2>
          <div style="text-align: center">
            <img src="@/assets/imgs/login-img.png" />
          </div>
        </div>
        <div class="right">
          <h1>欢迎登陆</h1>
          <div>
            <template v-if="$route.path === '/admin'">
              <AdminLogin />
            </template>
            <template v-else>
              <UserLogin />
            </template>
          </div>
        </div>
      </div>
    </el-main>

    <!-- <Footer /> -->
  </el-container>
</template>

<script>
import Footer from "@/components/Footer";
import LoginFunc from "@/function-namespace/auth/LoginFunc";
import utils from "@/utils/utils";
import loginFunc from "@/function-namespace/auth/LoginFunc";

import AdminLogin from "./AdminLogin.vue";
import UserLogin from "./UserLogin.vue";

export default {
  name: "Login",
  components: { Footer, AdminLogin, UserLogin },
  data() {
    return {
      ...LoginFunc,
    };
  },
  created() {
    // 检验用户是否存在token,存在直接跳转主页
    utils.checkToken("/index");
  },
  mounted() {
    loginFunc.changeCode();
  },
};
</script>

<style scoped lang="scss">
@import "../../assets/css/auth/login";
.login-container {
  height: 600px;
  display: flex;
  background: #fff;
  border-radius: 16px;
  overflow: hidden;

  .left {
    width: 600px;
    background: linear-gradient(180deg, #3572fb 0%, #f4f7ff 100%);
    background-size: cover;
    color: #ffffff;
    padding: 40px;
    h1 {
      font-size: 40px;
      font-weight: bold;
      margin: 0;
    }
    h2 {
      font-size: 24px;
      font-weight: 400;
      margin-top: 12px;
    }
    img {
      width: 400px;
      height: 400px;
    }
  }
  .right {
    width: 600px;
    padding: 120px;
    h1 {
      font-weight: bold;
      font-size: 24px;
      text-align: center;
      margin-bottom: 64px;
      margin-top: 0;
    }
  }
}

@media (max-width: 1441px) {
  .login-container {
    height: 480px;
    .left,
    .right {
      width: 480px;
      height: 480px;
    }
    .left {
      img {
        width: 200px;
        height: 200px;
      }
    }
    .right {
      padding: 70px;
    }
  }
}
</style>

<template>
  <el-form
    :model="loginForm"
    :rules="loginFormRules"
    ref="loginForm"
    :status-icon="true"
    label-width="100px"
  >
    <el-form-item prop="mobile">
      <el-input v-model="loginForm.mobile" placeholder="手机号码">
        <i
          slot="prefix"
          class="el-input__icon"
          style="display: flex; align-items: center; justify-content: center"
        >
          <img
            style="width: 14px; height: 16px"
            src="../../assets/imgs/ic_user.png"
          />
        </i>
      </el-input>
    </el-form-item>

    <el-form-item prop="code" class="sms-code" style="">
      <el-input v-model="loginForm.verifyCode" placeholder="验证码">
        <i
          slot="prefix"
          class="el-input__icon"
          style="display: flex; align-items: center; justify-content: center"
        >
          <img
            style="width: 14px; height: 16px"
            src="../../assets/imgs/ic_password.png"
          />
        </i>
      </el-input>
      <el-button
        style="width: auto; margin-left: 15px; position: absolute; right: 16px"
        :disabled="smsCodeFetching"
        @click="getSmsCode()"
        type="text"
        id="smsCode"
      >
        {{ smsCodeBtnText }}</el-button
      >
    </el-form-item>

    <el-form-item>
      <el-button
        style="width: 100%"
        type="primary"
        @click="login()"
        icon="el-icon el-icon-s-promotion"
      >
        登录
      </el-button>
      <el-button
        v-show="false"
        type="warning"
        @click="toRegisterPage"
        icon="el-icon el-icon-circle-plus"
      >
        学员注册
      </el-button>
    </el-form-item>
  </el-form>
</template>

<script>
import Footer from "@/components/Footer";
import utils from "@/utils/utils";
import { checkPhone } from "@/utils/validUtils";
import auth from "@/api/auth";
import router from "@/router";

export default {
  name: "ApplicantLogin",
  components: { Footer },
  data() {
    return {
      // 登录表单数据信息
      loginForm: {
        mobile: "",
        // 验证码
        verifyCode: "",
      },
      smsCodeBtnText: "获取验证码",
      smsCodeFetching: false,
      loginFormRules: {
        mobile: [
          {
            required: true,
            message: "请输入手机号",
            trigger: "blur",
          },
          { validator: checkPhone, trigger: "blur" },
        ],
        verifyCode: [
          {
            required: true,
            message: "请输入验证码",
            trigger: "blur",
          },
        ],
      },
    };
  },
  created() {
    // 检验用户是否存在token,存在直接跳转主页
    utils.checkToken("/curriculumVitae");
  },
  methods: {
    // 重置发送验证码按钮
    resetSend() {
      this.smsCodeBtnText = "获取验证码";
      this.smsCodeFetching = false;
    },
    getSmsCode() {
      this.$refs["loginForm"].validateField("mobile", (mobileError) => {
        if (!mobileError) {
          this.smsCodeFetching = true;
          this.smsCodeBtnText = "发送中...";
          auth
            .getSmsCode({ mobile: this.loginForm.mobile })
            .then((resp) => {
              if (resp.code === 200) {
                this.$message.success(resp.message);
                //设置倒计时
                let count = 60;
                const timer = setInterval(() => {
                  if (count-- === 0) {
                    clearInterval(timer);
                    this.resetSend();
                    return;
                  }
                  this.smsCodeBtnText = `${count}s后重新获取`;
                }, 1000);
              } else {
                this.$message.error(resp.message);
                this.resetSend();
              }
            })
            .catch(() => {
              this.resetSend();
            });
        }
      });
    },
    toRegisterPage() {
      router.push("/register");
    },
    isMobile() {
      return navigator.userAgent.match(
        /(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i
      );
    },
    // 登录
    login() {
      utils.validFormAndInvoke(this.$refs["loginForm"], () => {
        auth
          .checkSmsCode(this.loginForm)
          .then((resp) => {
            if (resp.code === 200) {
              console.info(resp);
              localStorage.setItem("authorization", resp.data.token);
              this.$message.info("登陆成功^_^");
              router.push("/curriculumVitae");
              // 暂时隐藏移动端
              /* if (this.isMobile()) {
                router.push("/mcvForm");
              } else {
                router.push("/curriculumVitae");
              }*/
            }
          })
          .catch((err) => {
            console.log(err);
            this.$message.error(err.response.data.errMsg);
          });
      });
    },
  },
};
</script>

<style scoped lang="scss">
@import "../../assets/css/auth/applicatLogin";
</style>
<style>
.sms-code .el-form-item__content {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
.box-card-change {
  width: 450px;
}
@media screen and (max-width: 500px) {
  .box-card-change {
    width: 80%;
  }
}
</style>

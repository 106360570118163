<template>
  <el-form
    :model="loginForm"
    :rules="loginFormRules"
    ref="loginForm"
    :status-icon="true"
    label-width="100px"
  >
    <el-form-item prop="username">
      <el-input v-model="loginForm.username" placeholder="账号">
        <i
          slot="prefix"
          class="el-input__icon"
          style="display: flex; align-items: center; justify-content: center"
        >
          <img
            style="width: 14px; height: 16px"
            src="../../assets/imgs/ic_user.png"
          />
        </i>
      </el-input>
    </el-form-item>

    <el-form-item prop="password">
      <el-input v-model="loginForm.password" placeholder="密码" show-password>
        <i
          slot="prefix"
          class="el-input__icon"
          style="display: flex; align-items: center; justify-content: center"
        >
          <img
            style="width: 14px; height: 16px"
            src="../../assets/imgs/ic_password.png"
          />
        </i>
      </el-input>
    </el-form-item>

    <el-form-item prop="code">
      <el-input class="code" v-model="loginForm.code" placeholder="验证码">
        <i
          slot="prefix"
          class="el-input__icon"
          style="display: flex; align-items: center; justify-content: center"
        >
          <img
            style="width: 14px; height: 16px"
            src="../../assets/imgs/ic_password.png"
          />
        </i>
      </el-input>
      <img
        src="/util/getCodeImg"
        @click="changeCode"
        id="code"
        style="float: right; margin-top: 4px; cursor: pointer"
        title="看不清,点击刷新"
        alt="验证码"
      />
    </el-form-item>

    <el-form-item>
      <el-button
        style="width: 100%; margin-top: 40px"
        type="primary"
        @click="login($refs['loginForm'])"
        icon="el-icon el-icon-s-promotion"
      >
        登录
      </el-button>
      <el-button
        v-show="false"
        type="warning"
        @click="toRegisterPage"
        icon="el-icon el-icon-circle-plus"
      >
        学员注册
      </el-button>
    </el-form-item>
  </el-form>
</template>

<script>
import Footer from "@/components/Footer";
import LoginFunc from "@/function-namespace/auth/LoginFunc";
import utils from "@/utils/utils";
import loginFunc from "@/function-namespace/auth/LoginFunc";

export default {
  name: "Login",
  components: { Footer },
  data() {
    return {
      ...LoginFunc,
    };
  },
  created() {
    // 检验用户是否存在token,存在直接跳转主页
    utils.checkToken("/index");
  },
  mounted() {
    loginFunc.changeCode();
  },
};
</script>

<style scoped lang="scss">
@import "../../assets/css/auth/login";
</style>
